.footer {
  height: 50px;
}

.dev {
  background-color: blueviolet;
}

.table tr.visible.transition {
  display: table-row !important;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}